import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import Animation from '../../utils/Animation';
// import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import headerBg from "../../assets/img/headerBg.jpg";

// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { AiOutlineLogin, AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

import logo from "../../assets/img/logo.png";
import "./navBarUp.css";
import { LangContext } from "../../context/LangContext";

import Slide from "@mui/material/Slide";
import useFetchData from "../../utils/ApiFetch";

const NavData = [
  {
    id: 1,
    name: "About Us",
    slug: "about-us",
    nameNp: "हाम्रो बारे",
    subNavData: [
      {
        name: "Introduction",
        nameNp: "परिचय",
        link: "/about-intro",
      },
      {
        name: "Board of Director",
        nameNp: "संचालक समिति",
        link: "/about-bod",
      },
      {
        name: "Audit Committee",
        nameNp: "लेखापरीक्षण समिति",
        link: "/about-audit",
      },
      {
        name: "Management Team",
        nameNp: "व्यवस्थापन समूह",
        link: "/about-management",
      },
    ],
  },
  {
    id: 2,
    slug: "tariffs",
    name: "Tariffs",
    nemeNp: "शुल्कहरू",
    subNavData: [
      {
        name: "STC-Credit",
        nameNp: "STC-क्रेडिट",
        link: "/tariffs/credit",
      },
      {
        name: "STC-Trade Finance",
        nameNp: "STC-व्यापार वित्त",
        link: "/tariffs/trade-finance",
      },
      {
        name: "STC-Operations",
        nameNp: "STC-सञ्चालन",
        link: "/tariffs/operations",
      },
      {
        name: "STC-Digital Products",
        nameNp: "STC-डिजिटल उत्पादनहरू",
        link: "/tariffs/digital-products",
      },
    ],
  },
  {
    id: 3,
    name: "Services",
    slug: "services",
    nemeNp: "सेवाहरू",
    subNavData: [
      {
        name: "Accounts",
        nameNp: "खाताहरू",
        link: "/service/accounts",
      },
      {
        name: "Loans",
        nameNp: "कर्जा",
        link: "/service/loans",
      },
      {
        name: "Digital Services",
        nameNp: "डिजिटल सेवाहरू",
        link: "/service/cards",
      },
      {
        name: "Safe Deposit Locker",
        nameNp: "सुरक्षित लकर",
        link: "/service/safe-deposit-locker",
      },
      {
        name: "DP Services",
        nameNp: "DP Services",
        link: "/service/dp-services",
      },
      {
        name: "Online Services",
        nameNp: "अनलाइन सेवाहरू",
        nestedSubNav: [
          {
            name: "Guarantee Verification",
            nameNp: "ग्यारेन्टी प्रमाणीकरण",
            externalLink: "",
            internalLink: "/guarantee-verification",
          },
          {
            name: "Online Fixed Deposit Opening",
            nameNp: "अनलाइन फिक्स्ड डिपोजिट खोल्ने",
            externalLink: "https://eaccount.primebank.com.np/fd",
            // externalLink: "#",
            internalLink: "",
          },
          {
            name: "Online Account Opening",
            nameNp: "अनलाइन खाता खोल्ने",
            externalLink: "https://eaccount.primebank.com.np/",
            internalLink: "",
          },
          {
            name: "E-Banking Login",
            nameNp: "ई-बैंकिङ लगइन",
            externalLink: "https://www.eprimebank.com/",
            internalLink: "",
          },
          // {
          //   name: "Online DMAT A/C Login",
          //   nameNp: "अनलाइन DMAT खाता लगइन",
          //   externalLink: "https://onlinedemat.primebank.com.np/",
          //   internalLink: "",
          // },
          {
            name: "Connect IPS Login",
            nameNp: "IPS लग - इन",
            externalLink: "https://login.connectips.com/#/login",
            internalLink: "",
          },
          // {
          //   name: "Bank XP Login",
          //   nameNp: "बैंक XP लग - इन",
          //   externalLink: "https://bankxp.primebank.com.np/",
          //   internalLink: "",
          // },
        ],
      },

      {
        name: "Remit CRN",
        nameNp: "Remit CRN",
        link: "/remit_crn",
      },
    ],
  },
  {
    id: 4,
    slug: "investor-relation",
    name: "Investor Relation",
    nameNp: "लगानीकर्तासँगको सम्बन्ध",
    subNavData: [
      {
        name: "Basel Disclosure",
        nameNp: "बेसल प्रकटीकरण",
        link: "/investor-relation/basel",
      },
      {
        name: "Quarterly Reports",
        nameNp: "त्रैमासिक प्रतिवेदनहरू",
        link: "/investor-relation/quarterly-reports",
      },
      {
        name: "Annual Reports",
        nameNp: "वार्षिक प्रतिवेदनहरू",
        link: "/investor-relation/annual-reports",
      },
      {
        name: "Base rate & Interest Rates",
        nameNp: "आधार दर र ब्याज दर",
        link: "/investor-relation/base-rate-and-interest-rates",
      },
      {
        name: "AGM Minutes",
        nameNp: "साधारण सभाको विवरण",
        link: "/investor-relation/agm-minute",
      },
      {
        name: "Corporate Governance Report",
        nameNp: "वित्तीय सुशासन प्रतिवेदन",
        link: "/investor-relation/corporate-governance-report",
      },
      {
        name: "Annexure-14",
        nameNp: "परिशिष्ट-१४",
        link: "/investor-relation/annexure-14",
      },
      {
        name: "Annexure-15",
        nameNp: "परिशिष्ट-१५",
        link: "/investor-relation/annexure-15",
      },
      {
        name: "Principal Financial Indicators",
        nameNp: "प्रमुख वित्तीय सूचकहरू",
        link: "/investor-relation/principal-financial-indicators",
      },
      {
        name: "Subsidized Loan",
        nameNp: "सहुलियत पुर्ण कर्जा",
        link: "/investor-relation/subsidized-loan",
      },
      {
        name: "Refinance Loan",
        nameNp: "पुनर्वित्त कर्जा",
        link: "/investor-relation/refinance-loan",
      },
      {
        name: "Right to Information",
        nameNp: "सूचनाको हक",
        link: "/investor-relation/right-to-information",
      },
      {
        name: "Corporate Social Responsibility",
        nameNp: "कर्पोरेट सामाजिक उत्तरदायित्व",
        link: "/csr-report",
      },
    ],
  },
  {
    id: 5,
    slug: "career",
    name: "Career",
    nameNp: "आवश्यकता",
    subNavData: [
      {
        name: "Grow with Us!",
        nameNp: "हामीसँग बढ्नुहोस्",
        link: "/career/grow-with-us",
      },
      {
        name: "Contact HRD",
        nameNp: "मानव संसाधन विभागलाई सम्पर्क गर्नुहोस्",
        link: "/career/contact-hr",
      },
      {
        name: "Current Vacancies",
        nameNp: "हालको रिक्त पदहरू",
        link: "/current-vacancy",
      },
    ],
  },
  {
    id: 6,
    slug: "our-network",
    name: "Our Network",
    nameNp: "हाम्रो संजाल",
    subNavData: [
      {
        name: "Branches / Information Officer",
        nameNp: "शाखा / सूचना अधिकारी",
        link: "/branches",
      },
      {
        name: "ATMs",
        nameNp: "एटीएम स्थानहरु",
        link: "/atms",
      },
      {
        name: "Province Office / Information Officer",
        nameNp: "प्रदेश कार्यालयहरु / सूचना अधिकारी",
        link: "/province-head",
      },
    ],
  },
  {
    id: 7,
    slug: "grievance",
    name: "Grievance",
    nameNp: "गुनासो",
    subNavData: [
      {
        name: "PCBL Grievance",
        nameNp: "PCBL गुनासो",
        link: "/grievance-form",
      },
      {
        name: "NRB Grievance Portal",
        nameNp: "NRB गुनासो पोर्टल",
        link: "",
        externalLink: "https://gunaso.nrb.org.np/",
      },
    ],
  },
  {
    id: 8,
    slug: "quick-links",
    name: "Quick Links",
    nameNp: "द्रुत लिङ्कहरू",
    subNavData: [
      {
        name: "Visa Member Banks List",
        nameNp: "भिसा कार्ड सदस्य बैंकहरूको सूची",
        link: "/visa-member-banks-list",
      },
      {
        name: "Banking Hours",
        nameNp: "बैँकिङ्ग समय",
        link: "/banking-hours",
      },
      {
        name: "Remitttance Services",
        nameNp: "रेमिटेन्स सेवाहरू",
        link: "/remittance-services",
      },
      {
        name: "Correspondent Banks",
        nameNp: "सम्पर्क बैक विवरण",
        link: "/correspondent-banks",
      },
      {
        name: "Learning and Development",
        nameNp: "सिकाइ र विकास",
        link: "/learning-and-development",
      },
      {
        name: "NT Bill Payment",
        nameNp: "NT बिल भुक्तानी",
        link: "/nt-bill-payment",
      },
      {
        name: "NEA Bill Payment",
        nameNp: "NEA बिल भुक्तानी",
        link: "/nea-bill-payment",
      },
    ],
  },
  {
    id: 9,
    slug: "services-mobile",
    name: "Services",
    nameNp: "सेवाहरू",
    subNavData: [
      {
        name: "Accounts",
        nameNp: "खाताहरू",
        link: "/service/accounts",
      },
      {
        name: "Loans",
        nameNp: "कर्जा",
        link: "/service/loans",
      },
      {
        name: "Digital Services",
        nameNp: "डिजिटल सेवाहरू",
        link: "/service/cards",
      },
      {
        name: "Safe Deposit Locker",
        nameNp: "सुरक्षित लकर",
        link: "/service/safe-deposit-locker",
      },
      {
        name: "DP Services",
        nameNp: "DP Services",
        link: "/service/dp-services",
      },
      {
        name: "Remit CRN",
        nameNp: "Remit CRN",
        link: "/remit_crn",
      },
    ],
  },
  {
    id: 10,
    slug: "online-services-mobile",
    name: "Online Services",
    nameNp: "अनलाइन सेवाहरू",
    subNavData: [
      {
        name: "Bank Guarantee",
        nameNp: "Bank Guarantee",
        link: "/guarantee-verification",
        internalLink: "/guarantee-verification",
      },
      {
        name: "Online Fixed Deposit Opening",
        nameNp: "अनलाइन फिक्स्ड डिपोजिट खोल्ने",
        link: "https://eaccount.primebank.com.np/fd",
        externalLink: "#",
        internalLink: "",
      },
      {
        name: "Online Account Opening",
        nameNp: "अनलाइन खाता खोल्ने",
        link: "https://eaccount.primebank.com.np/",
        internalLink: "",
      },
      {
        name: "E-Banking Login",
        nameNp: "ई-बैंकिङ लगइन",
        link: "https://www.eprimebank.com/",
        internalLink: "",
      },
      // {
      //   name: "Online DMAT A/C Login",
      //   nameNp: "अनलाइन DMAT खाता लगइन",
      //   link: "https://onlinedemat.primebank.com.np/",
      //   internalLink: "",
      // },
      {
        name: "Connect IPS Login",
        nameNp: "Connect IPS Login",
        link: "https://login.connectips.com/#/login",
        internalLink: "",
      },
      // {
      //   name: "Bank xp Login",
      //   nameNp: "Bank xp Login",
      //   link: "https://www.bankxp.primebank.com.np/",
      //   internalLink: "",
      // },
    ],
  },
];

const navAnimation = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2, duration: 0.5, type: "tween" },
  },
  hidden: { y: -30, opacity: 0 },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavbarUp = () => {
  // const {
  //       data,
  //       loading,
  //       error,
  // } = useFetchData('search/pagination/' + pageApi);

  const [searchOpen, setSearchOpen] = React.useState(false);

  const searchHandleClickOpen = () => {
    setSearchOpen(true);
  };

  const searchHandleClose = () => {
    setSearchOpen(false);
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const lang = useContext(LangContext);
  const np = lang.state.np;

  const [language, setLanguage] = React.useState("");
  const [drawerFound, setDrawerFound] = React.useState("");

  const findSubTabs = NavData?.find((obj) => {
    return obj.slug === drawerFound;
  });

  const onClickNP = () => {
    lang.dispatch({ type: "NEPALI" });
  };

  const onClickEN = () => {
    lang.dispatch({ type: "ENGLISH" });
  };

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSetIndex = (index) => {
    activeIndex !== index && setActiveIndex(index);
  };

  const handleRemoveIndex = (index) =>
    activeIndex === index && setActiveIndex(0);

  const LanguageSelect = styled(Select)(({ theme }) => ({}));

  return (
    <>
      <div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={navAnimation}
        className="TopAltNavBarBackground"
        // style={{backgroundImage: `url(${headerBg})`}}
        style={{
          background: `linear-gradient(rgb(169, 58, 124,0.7), rgb(169, 58, 124,0.4)), url(${headerBg})`,
        }}
      >
        <div className="TopAltNavbarContainer">
          <div className="TopAltNavbarItemsContainer">
            {/* <Link to="/calendar">
                    <p className='TopAltNavItemText'>{np ? 'पात्रो' : 'Calendar'}</p>
                    </Link> */}
            <div className="nav__menu-item">
              <p className="TopAltNavItemText">{np ? "आवश्यकता" : "Careers"}</p>
              <CareersSubMenu />
            </div>

            <div className="nav__menu-item">
              <p className="TopAltNavItemText">{np ? "शुल्कहरू" : "Tariffs"}</p>
              <TariffsSubMenu />
            </div>

            <div className="nav__menu-item">
              <p className="TopAltNavItemText">
                {np ? "लगानीकर्तासँगको सम्बन्ध" : "Investor Relation"}
              </p>
              <InvestorSubMenu />
            </div>

            <div className="nav__menu-item">
              <p className="TopAltNavItemText">
                {np ? "द्रुत लिङ्कहरू" : "Quick Links"}
              </p>
              <QuickLinkSubMenu />
            </div>

            <div className="TopAltNavToolsContainer">
              <a href="https://eaccount.primebank.com.np/fd" target="_blank">
                <div className="TopAltNavItemLoginButton">
                  {np
                    ? "अनलाइन फिक्स्ड डिपोजिट खोल्ने"
                    : " Online Fixed Deposit Opening"}
                </div>
              </a>
              {/* <a href="#" target="_blank">
                <div className="TopAltNavItemLoginButton">
                  {np
                    ? "अनलाइन फिक्स्ड डिपोजिट खोल्ने"
                    : " Online Fixed Deposit Opening"}
                </div>
              </a> */}
              <a href="https://eaccount.primebank.com.np/" target="_blank">
                <div className="TopAltNavItemLoginButton">
                  {np ? "अनलाइन खाता खोल्ने" : " Online Account Opening"}
                </div>
              </a>
              <a href="https://www.eprimebank.com/" target="_blank">
                <div className="TopAltNavItemLoginButton">
                  {/* <AiOutlineLogin size='18px' /> */}
                  {np ? "लग - इन" : " e-Bank Login"}
                </div>
              </a>

              {/* <a href="https://onlinedemat.primebank.com.np/" target="_blank">
                <div className="TopAltNavItemLoginButton">
                  {np ? "अनलाइन डिम्याट खाता" : "Online Demat A/C"}
                </div>
              </a> */}

              {/* <Link to ="/select-atm-or-branch">
                        <div className='TopAltNavItemLoginButton'>
                          <AiOutlineLogin size='18px' />
                          Add Location
                        </div>
                        </Link> */}

              {/* <select className='TopAltNavItemLangButton' value={language} onChange={handleChange}>
                          <option className='TopAltNavItemLangButtonOption' value={1} onClick={() => {onClickEN()}}>English</option>
                          <option className='TopAltNavItemLangButtonOption' value={2} onClick={() => {onClickNP()}}>नेपाली</option>
                        </select> */}

              <Select
                sx={{
                  p: 0,
                  color: "#fff",
                }}
                className="TopAltNavItemEnglishButton"
                defaultValue={Number(1)}
                inputProps={{
                  "aria-label": "Without label",
                  disableUnderline: true,
                }}
                onChange={handleChange}
              >
                <MenuItem
                  value={Number(1)}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickEN();
                  }}
                >
                  English
                </MenuItem>
                <MenuItem
                  value={Number(2)}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickNP();
                  }}
                >
                  नेपाली
                </MenuItem>
              </Select>
              {/* <Search className='SearchBox'>
                              <SearchIconWrapper
                              className="searchSubmitIconWrapper">
                              <SearchIcon className="searchSubmitIcon" />
                              </SearchIconWrapper>
                              <StyledInputBase
                                  type="text"
                                  name="search"
                                  placeholder= {np? "खोज ..." : "Search…"}
                                  inputProps={{ 'aria-label': 'search' }}
                              />
                          </Search> */}

              <Link to="/search">
                <div className="TopAltNavItemLoginButton">
                  <AiOutlineSearch
                    className="navSearchBtnIcon"
                    fontSize="24px"
                    color="#fff"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={navAnimation}
        className="MainNavBarBackground"
      >
        <div className="MainNavbarContainer">
          <Link to="/">
            <img className="MainNavbarLogo" src={logo} alt="navbarLogo" />
          </Link>

          <div className="MainNavbarNavItemsFlexContainer">
            <Link to="/">
              <p className="MainNavbarNavItem">{np ? "मुख्य पृष्ठ" : "Home"}</p>
            </Link>

            <div className="nav__menu-item">
              <p className="MainNavbarNavItem">
                {np ? "हाम्रो बारे" : "About Us"}
              </p>
              <SubMenu />
            </div>
            {/* <div className='nav__menu-item'>
                    <p className="MainNavbarNavItem">Our Team</p>
                    <SubMenu />
                </div> */}
            {/* <div className='nav__menu-item'>
                    <p className="MainNavbarNavItem">Services</p>
                    <SubMenu />
                </div> */}
            <div className="nav__menu-item">
              <p className="MainNavbarNavItem">{np ? "सेवाहरू" : "Services"}</p>
              <ServicesSubMenu />
            </div>

            <div className="nav__menu-item">
              <p className="MainNavbarNavItem">
                {np ? "हाम्रो संजाल" : "Our Network"}
              </p>
              <NetworkSubMenu />
            </div>

            <div className="nav__menu-item">
              <p className="MainNavbarNavItem">{np ? "गुनासो" : "Grievance"}</p>
              <GrievanceSubMenu />
            </div>

            <Link to="/downloads">
              <p className="MainNavbarNavItem">
                {np ? "डाउनलोडहरू" : "Downloads"}
              </p>
            </Link>

            <Link to="/get-in-touch">
              <p className="MainNavbarNavItem">
                {np ? "सम्पर्क गर्नुहोस्" : "Contact Us"}
              </p>
            </Link>
          </div>
        </div>
      </div>

      <div className="MobileNavBarContainer">
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="MobileDrawerContainer"
        >
          <img src={logo} alt="logo" />

          {/* <Search className='SearchBox'>
                            <SearchIconWrapper>
                            <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder={np? "खोज ..." : "Search…"}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                  </Search>   */}

          <div className="MobileDrawerNavItems">
            {/* home */}
            <div>
              <Link
                to="/"
                className="MobileDrawerSingleNavItem"
                onClick={toggleDrawer}
              >
                {np ? "मुख्य पृष्ठ" : "Home"}
              </Link>
              <hr className="MobileDrawerHR" />
            </div>

            {/* about */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(1);
                  handleRemoveIndex(1);
                  setDrawerFound("about-us");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "हाम्रो बारे" : "About Us"}
                </a>
                {activeIndex === 1 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>
              <hr className="MobileDrawerHR" />

              {activeIndex === 1 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {/* {findSubTabs?.subNavData.map((value, index) => 
                        <Link 
                        key={index} 
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}>{np? `${value.nameNp}` : `${value.name}`}</Link>          
                        )} */}
                    <AboutSubMenuMobile toggleDrawer={toggleDrawer} />
                    {/* <p className="MainNavbarNavItem">{np? 'हाम्रो बारे' : 'About Us'}</p>
              <SubMenu /> */}
                  </div>
                </div>
              )}
            </div>

            {/* services */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(2);
                  handleRemoveIndex(2);
                  setDrawerFound("services-mobile");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "सेवाहरू" : "Services"}
                </a>
                {activeIndex === 2 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 2 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* online-services */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(10);
                  handleRemoveIndex(10);
                  setDrawerFound("online-services-mobile");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "अनलाइन सेवाहरू" : "Online Services"}
                </a>
                {activeIndex === 10 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 10 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* network */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(3);
                  handleRemoveIndex(3);
                  setDrawerFound("our-network");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "हाम्रो नेटवर्क" : "Our Network"}
                </a>
                {activeIndex === 3 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 3 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* grievance */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(7);
                  handleRemoveIndex(7);
                  setDrawerFound("grievance");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "गुनासो" : "Grievance"}
                </a>
                {activeIndex === 7 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 7 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <>
                        {value.externalLink ? (
                          <a
                            key={index}
                            href={value.externalLink}
                            target="_blank"
                            className="MobileDrawerSubTabItem"
                            onClick={toggleDrawer}
                          >
                            {np ? `${value.nameNp}` : `${value.name}`}
                          </a>
                        ) : (
                          <Link
                            key={index}
                            to={value.link}
                            className="MobileDrawerSubTabItem"
                            onClick={toggleDrawer}
                          >
                            {np ? `${value.nameNp}` : `${value.name}`}
                          </Link>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* contact us */}
            <div>
              <Link
                to="/get-in-touch"
                className="MobileDrawerSingleNavItem"
                onClick={toggleDrawer}
              >
                {np ? "सम्पर्क गर्नुहोस्" : "Contact Us"}
              </Link>
              <hr className="MobileDrawerHR" />
            </div>
            {/* Calendar */}
            {/* <div>
                    <Link 
                    to="/calendar" 
                    className="MobileDrawerSingleNavItem"
                    onClick={toggleDrawer}
                    >{np? 'पात्रो' : 'Calendar'}</Link>
                    <hr className="MobileDrawerHR" />
                    </div>  */}

            {/* career */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(4);
                  handleRemoveIndex(4);
                  setDrawerFound("career");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "आवश्यकता" : "Career"}
                </a>
                {activeIndex === 4 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 4 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Tariffs */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(6);
                  handleRemoveIndex(6);
                  setDrawerFound("tariffs");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "शुल्कहरू" : "Tariffs"}
                </a>
                {activeIndex === 6 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 6 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Investor Relation */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(5);
                  handleRemoveIndex(5);
                  setDrawerFound("investor-relation");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "लगानीकर्तासँगको सम्बन्ध" : "Investor Relation"}
                </a>
                {activeIndex === 5 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 5 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Quick Links */}
            <div>
              <div
                onClick={() => {
                  handleSetIndex(8);
                  handleRemoveIndex(8);
                  setDrawerFound("quick-links");
                }}
                className="MobileDrawerSingleNavItemDropDownFlexContainer"
              >
                <a className="MobileDrawerSingleNavItem">
                  {np ? "द्रुत लिङ्कहरू" : "Quick Links"}
                </a>
                {activeIndex === 8 ? (
                  <IoIosArrowForward className="" />
                ) : (
                  <IoIosArrowDown className="" />
                )}
              </div>

              <hr className="MobileDrawerHR" />

              {activeIndex === 8 && (
                <div className="accordion-content">
                  <div className="MobileDrawerSubTabContainer">
                    {findSubTabs?.subNavData.map((value, index) => (
                      <Link
                        key={index}
                        to={value.link}
                        className="MobileDrawerSubTabItem"
                        onClick={toggleDrawer}
                      >
                        {np ? `${value.nameNp}` : `${value.name}`}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="MobileDrawerLoginLangButtonsContainer">
              <a
                href="https://eaccount.primebank.com.np/fd"
                target="_blank"
                onClick={toggleDrawer}
              >
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineLogin size="18px" />
                  {np
                    ? "अनलाइन फिक्स्ड डिपोजिट खोल्ने"
                    : " Online Fixed Deposit Opening"}
                </div>
              </a>
              {/* <a href="#" target="_blank" onClick={toggleDrawer}>
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineLogin size="18px" />
                  {np
                    ? "अनलाइन फिक्स्ड डिपोजिट खोल्ने"
                    : " Online Fixed Deposit Opening"}
                </div>
              </a> */}

              <a
                href="https://eaccount.primebank.com.np/primebank-account-opening"
                target="_blank"
                onClick={toggleDrawer}
              >
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineLogin size="18px" />
                  {np ? "अनलाइन खाता खोल्ने" : "Online Account Opening"}
                </div>
              </a>
              <a
                href="https://www.eprimebank.com/"
                target="_blank"
                onClick={toggleDrawer}
              >
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineLogin size="18px" />
                  {np ? "लग - इन" : "e-Bank Login"}
                </div>
              </a>

              {/* <Link 
                    to="primebankv02/select-atm-or-branch"
                    onClick={toggleDrawer}>
                      <div className='TopAltNavItemLoginButtonDrawer'>
                          <AiOutlineLogin size='18px' />
                          Add Location
                      </div>
                    </Link> */}

              {/* <a
                href="https://onlinedemat.primebank.com.np/"
                target="_blank"
                onClick={toggleDrawer}
              >
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineLogin size="18px" />
                  {np ? "अनलाइन डिम्याट खाता" : "Online Demat A/C"}
                </div>
              </a> */}

              <Select
                sx={{
                  p: 0,
                  color: "#0f2137",
                  "& .Mui-focused": {
                    backgroundColor: "red",
                  },
                }}
                className="TopAltNavItemEnglishButton"
                defaultValue={1}
                inputProps={{
                  "aria-label": "Without label",
                  disableUnderline: true,
                }}
                onChange={handleChange}
              >
                <MenuItem
                  value={1}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickEN();
                    toggleDrawer();
                  }}
                >
                  English
                </MenuItem>
                <MenuItem
                  value={2}
                  className="NavbarLanguageMenuItem"
                  onClick={() => {
                    onClickNP();
                    toggleDrawer();
                  }}
                >
                  नेपाली
                </MenuItem>
              </Select>

              <Link to="/search" onClick={toggleDrawer}>
                <div className="TopAltNavItemLoginButtonDrawer">
                  <AiOutlineSearch size="18px" />
                  {np ? "खोज" : "Search"}
                </div>
              </Link>
            </div>
          </div>
        </Drawer>

        <div className="mobileNavImageContainer">
          <Link to="/">
            <img className="mobileNavLogoImage" src={logo} alt="logo" />
          </Link>

          <GiHamburgerMenu onClick={toggleDrawer} size="24px" color="3a3a3a" />
        </div>
      </div>
    </>
  );
};

const ServicesSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;

  const found = NavData?.find((obj) => {
    return obj.slug === "services";
  });

  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item">
          {value.nestedSubNav ? (
            <div className="nested-container">
              <p className="nested-trigger">
                {np ? `${value.nameNp}` : `${value.name}`}
              </p>
              <ul className="nav__submenu__nested">
                {value.nestedSubNav.map(
                  (i, index) => (
                    <>
                      {i.externalLink.length < 1 ? (
                        <li key={index} className="nav__submenu-item">
                          <Link to={i.internalLink}>
                            {np ? `${i.nameNp}` : `${i.name}`}
                          </Link>
                        </li>
                      ) : (
                        <li key={index} className="nav__submenu-item">
                          <a href={i.externalLink} target="_blank">
                            {np ? `${i.nameNp}` : `${i.name}`}
                          </a>
                        </li>
                      )}
                    </>
                  )

                  // <li key={index} className="nav__submenu-item">
                  // <a href={i.externalLink} target="_blank">
                  // {np? `${i.nameNp}` : `${i.name}`}
                  // </a>
                  // </li>
                )}
              </ul>
            </div>
          ) : (
            <Link to={value.link}>
              {np ? `${value.nameNp}` : `${value.name}`}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

const InvestorSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "investor-relation";
  });

  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          <Link to={value.link}>
            {np ? `${value.nameNp}` : `${value.name}`}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const AboutSubMenuMobile = (props) => {
  const { data, loading, error } = useFetchData("/site_settings");
  const lang = useContext(LangContext);
  const np = lang.state.np;

  return (
    <>
      <Link
        to={`/about-intro`}
        className="MobileDrawerSubTabItem"
        onClick={props.toggleDrawer}
      >
        {np ? "परिचय" : "Introduction"}
      </Link>

      {data?.team_group?.map((value) => (
        <Link
          key={value.order}
          to={`/team-members/${value.id}`}
          className="MobileDrawerSubTabItem"
          onClick={props.toggleDrawer}
        >
          {np ? `${value.group_name_nepali}` : `${value.group_name}`}
        </Link>
      ))}
    </>
  );
};

const SubMenu = () => {
  const { data, loading, error } = useFetchData("/site_settings");
  const lang = useContext(LangContext);
  const np = lang.state.np;

  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      <li className="nav__submenu-item ">
        <Link to={`/about-intro`}>{np ? "परिचय" : "Introduction"}</Link>
      </li>
      {data?.team_group?.map((value) => (
        <li key={value.order} className="nav__submenu-item ">
          <Link to={`/team-members/${value.id}`}>
            {np
              ? value.group_name_nepali.length === 0
                ? value.group_name
                : value.group_name_nepali
              : value.group_name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

// const SubMenu = () => {
//   const lang = useContext(LangContext);
//   const np = lang.state.np;

//   const found = NavData?.find(obj => {
//     return obj.slug === 'about-us'
//   })

//   return(
//       <ul className="nav__submenu">
//         <hr className='DeskTopSubNavBarTopHR' />
//         {found?.subNavData.map((value, index) =>
//           <li key={index} className="nav__submenu-item ">
//           <Link to={value.link}>
//             {np? `${value.nameNp}` : `${value.name}`}
//           </Link>
//           </li>
//         )}
//     </ul>
//   );
// }

const TariffsSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "tariffs";
  });
  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          <Link to={value.link}>
            {np ? `${value.nameNp}` : `${value.name}`}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const CareersSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "career";
  });
  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          <Link to={value.link}>
            {np ? `${value.nameNp}` : `${value.name}`}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const NetworkSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "our-network";
  });
  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          <Link to={value.link}>
            {np ? `${value.nameNp}` : `${value.name}`}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const GrievanceSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "grievance";
  });
  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          {value.externalLink ? (
            <a href={value.externalLink} target="_blank">
              {np ? `${value.nameNp}` : `${value.name}`}
            </a>
          ) : (
            <Link to={value.link}>
              {np ? `${value.nameNp}` : `${value.name}`}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

const QuickLinkSubMenu = () => {
  const lang = useContext(LangContext);
  const np = lang.state.np;
  const found = NavData?.find((obj) => {
    return obj.slug === "quick-links";
  });

  return (
    <ul className="nav__submenu">
      <hr className="DeskTopSubNavBarTopHR" />
      {found?.subNavData.map((value, index) => (
        <li key={index} className="nav__submenu-item ">
          <Link to={value.link}>
            {np ? `${value.nameNp}` : `${value.name}`}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavbarUp;
