import * as React from "react";
import { LangContext } from "../../context/LangContext";
import { Link } from "react-router-dom";
import Animation from "../../utils/Animation";
import useFetchData from "../../utils/ApiFetch";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Typography from "@mui/material/Typography";

import branchIcon from "../../assets/img/branchIcon.png";
import downloadsIcon from "../../assets/img/downloadsIcon.png";
import faqIcon from "../../assets/img/faqIcon.png";
import touchIcon from "../../assets/img/touchIcon.png";

import facebook from "../../assets/img/socialIcons/facebook.png";
import instagram from "../../assets/img/socialIcons/instagram.png";
import linkedin from "../../assets/img/socialIcons/linkedin.png";
import youtube from "../../assets/img/socialIcons/youtube.png";

import "./footer.css";

const Footer = () => {
  const lang = React.useContext(LangContext);
  const np = lang.state.np;

  const { data, loading, error } = useFetchData("/site_settings");

  // const team = data?.team_group

  // const teamLoop = team?.forEach((i) => {
  //   console.log("i =>", typeof i, i);
  // })

  // console.log("team group =>", typeof teamLoop, teamLoop)

  return (
    <>
      <div className="footerTabsContainer">
        <Link to="/branches" className="footerTabSingleContainer">
          <img className="footerTabImage" src={branchIcon} alt="branchIcon" />
          <p className="footerTabText">
            {np ? "शाखा स्थान" : "Branch Location"}
          </p>
        </Link>

        <Link to="/faq" className="footerTabSingleContainer">
          <img className="footerTabImage" src={faqIcon} alt="faqIcon" />
          <p className="footerTabText">{np ? "कसरी" : "FAQs"}</p>
        </Link>

        <Link to="/downloads" className="footerTabSingleContainer">
          <img
            className="footerTabImage"
            src={downloadsIcon}
            alt="downloadsIcon"
          />
          <p className="footerTabText">{np ? "डाउनलोडहरू" : "Downloads"}</p>
        </Link>

        <Link to="/get-in-touch" className="footerTabSingleContainer">
          <img className="footerTabImage" src={touchIcon} alt="touchIcon" />
          <p className="footerTabText">
            {np ? "सम्पर्कमा रहनुहोस्" : "Get in Touch"}
          </p>
        </Link>
      </div>

      <Box className="FooterMain" component="div">
        <Box className="FLEXBOX container1" component="div">
          <Box className="FootBox" component="div">
            <Typography className="footerListTitle" variant="h4">
              {np ? "सम्पर्कमा रहनुहोस्" : "Get in Touch"}
            </Typography>
            <div className="footerListContainer">
              <p className="footerListItem">
                {np
                  ? "ठेगाना: कमलपोखरी, काठमाडौं"
                  : "Address: KamalPokhari, Kathmandu"}
              </p>
              <p className="footerListItem">
                {np
                  ? "फोन: ०१-४४२३४३२"
                  : "Phone: +977 01-4523254,01-4523432/3 & 01- 4523436"}{" "}
              </p>
              <p className="footerListItem">
                {np
                  ? "एनटीसी टोल फ्री नम्बर: १८१०५०००१५०"
                  : "NTC Toll Free Number : 18105000150"}
              </p>
              <p className="footerListItem">
                {np
                  ? "एनसेल टोल फ्री नम्बर : १८१०२४४४५५५"
                  : "Ncell Toll Free Number :18102444555"}
              </p>
              <p className="footerListItem">
                {np ? "हुलाक कोड: 44600" : "Postal Code: 44600"}
              </p>
              <p className="footerListItem">
                {np ? "इमेल: info@pcbl.com.np" : "Email: info@pcbl.com.np"}{" "}
              </p>
            </div>

            {!loading && (
              <div className="footerSocialContainer">
                <a
                  href={data?.site_settings.facebook}
                  target="_blank"
                  className="footerSocialItem"
                >
                  <img
                    className="footerSocialImg"
                    src={facebook}
                    href="facebookIcon"
                  />
                </a>

                {/* <a href={data?.site_settings?.instagram} target="_blank" className='footerSocialItem'>
                <img className='footerSocialImg' src={instagram} href="instagramIcon" />
              </a> */}

                {/* <a href={data?.site_settings.linked_in} target="_blank" className='footerSocialItem'>
                <img className='footerSocialImg' src={linkedin} href="linkedinIcon" />
              </a> */}

                <a
                  href={data?.site_settings.youtube}
                  target="_blank"
                  className="footerSocialItem"
                >
                  <img
                    className="footerSocialImg"
                    src={youtube}
                    href="youtubeIcon"
                  />
                </a>
              </div>
            )}
          </Box>

          {!loading && (
            <Box className="FootBox" component="div">
              <Typography variant="h4">
                {np ? "हाम्रोबारे" : "About Us"}
              </Typography>
              <div className="footerListContainer">
                <Link to={"/about-intro"} className="footerListItem">
                  {np ? "परिचय" : "Introduction"}
                </Link>

                <Link to={"/team-members/27"} className="footerListItem">
                  {np ? "सञ्चालक समिति" : "Board of Directors"}
                </Link>

                {/* <Link to={'/team-members/30'} className='footerListItem'>{np?'जोखिम समिति':'Risk Committee'}  </Link>

              <Link to={'/team-members/31'} className='footerListItem'>{np?'AML समिति':'AML Committee'}</Link>

              <Link to={'/team-members/28'} className='footerListItem'>{np?'लेखापरीक्षण समिति':'Audit Committee'}</Link> */}

                <Link to={"/team-members/29"} className="footerListItem">
                  {np ? "व्यवस्थापन टोली" : "Management Team"}
                </Link>
              </div>
            </Box>
          )}

          <Box className="FootBox" component="div">
            <Typography variant="h4">{np ? "समर्थन" : "Support"}</Typography>
            <div className="footerListContainer">
              <Link to="/get-in-touch" className="footerListItem">
                {np ? "हामीलाई सम्पर्क गर्नुहोस" : "Contact Us"}
              </Link>
              {/* <a style={{ color: '#f2f2f2' }} href="https://gunaso.nrb.org.np/" target="_blank">{np?'नेपाल राष्ट्रिय बैंक गुनासो पोर्टल':'NRB Grievance Portal'}</a>
                <Link to="/grievance-form" className='footerListItem'>{np?'गुनासो फारम':'Grievance Form'}</Link>
                <Link to="/grievance-response" className='footerListItem'>{np?'गुनासो प्रतिक्रिया':'Grievance Response'}</Link> */}
              <Link to="/downloads" className="footerListItem">
                {np ? "डाउनलोडहरू" : "Downloads"}
              </Link>
              <Link to="/faq" className="footerListItem">
                {np ? "कसरी" : "Frequently Asked Questions"}
              </Link>
              <Link to="/branches" className="footerListItem">
                {np ? "शाखाहरु" : "Branches Location"}
              </Link>
              <Link to="/atms" className="footerListItem">
                {np ? "एटीएम स्थानहरु" : "ATM Location"}
              </Link>
            </div>
          </Box>
          <Box className="FootBox" component="div">
            <Typography variant="h4">Quick Links</Typography>
            <div className="footerListContainer">
              {/* <Link to="#" className='footerListItem'>{np? 'संवाददाता बैंकिङ विवरण' : 'Correspondent Banking details'}</Link> */}
              <Link to="/service-charges" className="footerListItem">
                {np ? "शुल्कहरू" : "Tariffs"}
              </Link>
              {/* <Link className='footerListItem' to="#">
                    {np?'लगानी बैंकिङ':'Investment Banking'}
                  </Link> */}
              <Link to="/banking-hours" className="footerListItem">
                {np ? "बैँकिङ्ग समय" : "Banking Hours"}
              </Link>
              <Link to="/public-information" className="footerListItem">
                {np ? "सार्वजनिक जानकारी" : "Public Information"}
              </Link>
              <Link to="/current-vacancy" className="footerListItem">
                {np ? "क्यारियर" : "Career"}
              </Link>
            </div>
          </Box>
        </Box>

        <div className="footerSocialBackground">
          <p className="footerCopyDesc">
            {np
              ? `प्रतिलिपि अधिकार © ${new Date().getFullYear()} प्राइम कमर्शियल बैंक लिमिटेड। सबै अधिकार सुरक्षित।`
              : `Copyright © ${new Date().getFullYear()} Prime Commercial Bank Limited. All Rights Reserved.`}
          </p>

          <p className="footerCopyDesc">
            {np ? "विकसित द्वारा : " : "Developed by : "}{" "}
            <a
              style={{ color: "#fff" }}
              href="https://nyatapol.com/"
              target="_blank"
            >
              {np ? "न्याटापोल प्रविधि" : "Nyatapol Technologies"}
            </a>
          </p>
        </div>
      </Box>
    </>
  );
};

export default Footer;
